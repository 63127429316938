<template>
  <div>
    <div class="profile-icons-svg position-relative cursor-pointer"
         @click="onFocus"
    >
      <i class="lar la-bell"></i>
      <div v-if="notification.length > 0">
        <div class="notify-badge">{{notification.length}}</div>
      </div>
      <div class="notification-box" v-if="isFocused">
        <div class="d-flex align-items-center justify-content-between iq-border-radius-10 p-0 bg-primary p-3 ">
          <p class="text-white font-size-18 m-0">الأشعارات</p>
          <div class="d-flex align-items-center gap_1">
            <p class="font-size-12 text-white m-0">تظهر فقط غير المقروءة</p>
            <label class="switch m-0">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div v-if="notification.length > 0" >
          <ul class="sub-list-card border-0 p-0 m-0" v-for="(notify , key) in notification" :key="key">
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">{{ notify.service}}</span>
                  <span>{{ notify.created_at }}</span>
                </p>
                <p class="m-0 font-size-14">{{ notify.content }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <ul class="sub-list-card border-0 p-0 m-0">
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">النقل</span>
                  <span>1/1/2023</span>
                </p>
                <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
              </div>
            </li>
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">النقل</span>
                  <span>1/1/2023</span>
                </p>
                <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
              </div>
            </li>
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">النقل</span>
                  <span>1/1/2023</span>
                </p>
                <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
              </div>
            </li>
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">النقل</span>
                  <span>1/1/2023</span>
                </p>
                <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
              </div>
            </li>
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">النقل</span>
                  <span>1/1/2023</span>
                </p>
                <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
              </div>
            </li>            <li class="d-flex flex-column border-bottom p-3">
            <div class="d-flex align-items-start mb-1 flex-column gap_1">
              <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                <span class="font-size-18">النقل</span>
                <span>1/1/2023</span>
              </p>
              <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
            </div>
          </li>
            <li class="d-flex flex-column border-bottom p-3">
              <div class="d-flex align-items-start mb-1 flex-column gap_1">
                <p class="text-primary mb-0 d-flex justify-content-between w-100 flex-row align-items-center">
                  <span class="font-size-18">النقل</span>
                  <span>1/1/2023</span>
                </p>
                <p class="m-0 text-secondary font-size-14">تم نقل الأبل بنجاح و تم دفع الرسوم المستحقه للسائق</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notificationServices from '@/layouts/services/layoutServices'
export default {
  name: 'userNotification',
  data () {
    return {
      isFocused: false,
      notification: []
    }
  },
  methods: {
    onFocus () {
      this.isFocused = true
    },
    allNotifications () {
      notificationServices.getAllNotifications().then(res => {
        this.notification = res.data.data
      })
    }
  },
  mounted () {
    window.addEventListener('click', (e) => {
      if (!e.target.closest('.profile-icons-svg')) {
        this.isFocused = false
      }
    })
  },
  created () {
    this.allNotifications()
  }
}
</script>

<style>

.notification-box{
  position: absolute;
  width: 350px;
  top: 50px;
  right: 0;
  -webkit-animation: fade-in-bottom .6s cubic-bezier(.39,.575,.565,1) both;
  animation: fade-in-bottom .6s cubic-bezier(.39,.575,.565,1) both;
  transform: translateY(100%);
  background-color: var(--iq-white);
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(40 41 61 / 4%), 0 16px 24px rgb(96 97 112 / 16%);
  transition: all .3s ease-out 0s;
}
.sub-list-card{
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  font-size: inherit;
  padding: 15px;
  line-height: normal;
  color: inherit;
  border-bottom: 1px solid var(--iq-secondary-dark);
  display: inline-block;
  width: 100%;
}
.sub-list-card li:hover {
  background-color: #f8f8f8;
  transition: background-color 0.3s ease-in-out;
}
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 22px;
  left: 0;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
